import React, {useEffect, useState} from 'react';
import {GET_COLLECTION_DATA, SEARCH_COLLECTION_URL} from '../../api';
import {getGeneralReport} from '../../request/users';
import 'datatables.net';
import 'datatables.net-responsive';
import 'select2';
import 'components-jqueryui';
import $ from "jquery";
import moment from "moment";
import formatNumber from "../../utils/formatNumber";
import {useSelector} from "react-redux";
import {capitalize} from "../../utils/capitalize";
import cogoToast from "cogo-toast";
import {student} from "../../request";
import AWS from "aws-sdk";
import generateId from "../../utils/generateChar";

const bucket = new AWS.S3({
    accessKeyId: "hcs",
    secretAccessKey: "HCS!2022%",
    endpoint: "https://cloudinary.zstudy.co",
    signatureVersion: 'v4',
    s3ForcePathStyle: true
});

const Dashboard = (props) => {

    document.title = "Dashboard";

    const token = localStorage.getItem('jwtToken');
    const auth = useSelector((state) => state.auth.user);
    const [editForm, setEditForm] = useState(false);
    const [editId, setEditId] = useState(false);
    const [formLoading, setFormLoading] = useState(false);

    useEffect(() => {
        getGeneralReport(auth).then((response) => {
            if(!response.error) {
                $('#total-students').text(formatNumber(response.payload.students));
                $('#total-academics').text(response.payload.academics);
                $('#total-grades').text(response.payload.grades);
            }
        });

        let td_student = $('#student-table').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Student Report</p>'
            },
            "searching": true,
            "lengthMenu": [[10], [10]],
            ordering: false,
            searchDelay: 650,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_COLLECTION_DATA(auth.user.level), {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "student",
                        fieldname: "firstName",
                        filter: {
                            academia: auth.user.level === 0 ? auth.user.sub : auth.user.academia._id
                        },
                        populate: "grade",
                        pageSize: data.start,
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success : function(res) {
                        let array = [];
                        res.data.map((data) => {array.push({raw: data})});
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.id_number || "not_set";
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.firstName ? `${data.firstName} ${data.lastName}` : "Not set";
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.grade ? data.grade.name : "Not Set"
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return moment(data.date.str).format("Do MMM, YYYY");
                    },
                    "targets": 3
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link view_student"><i class="fa fa-eye"></i> View</a> <a href="#" class="nav-link edit_student"><i class="fa fa-pencil"></i> Edit</a> <a href="#" class="nav-link delete_student"><i class="fa fa-trash-alt"></i> Delete</a></nav>`
                    },
                    "targets": 4
                }
            ]
        });

        td_student.on('click', '.edit_student', function(e) {
            e.preventDefault();
            let extract_td = td_student.row($(this).closest('tr')).data();
            setEditForm(true);
            setEditId(extract_td.raw._id);
            $("#firstName").val(extract_td.raw.firstName);
            $("#lastName").val(extract_td.raw.lastName);
            $("#id_number").val(extract_td.raw.id_number);

            initializeSearch();
            let grade_select = $("#grade-select2");
            let $option = $("<option selected></option>").val(extract_td.raw.grade._id).text(extract_td.raw.grade.name);
            grade_select.append($option).trigger('change');

            $('#password').prop("required", false);
            $('#cover_image').prop("required", false);
            $('#student_form').parsley();
            $("#modalStudent").modal({ backdrop: 'static', focus: false, show: true });

        });

        td_student.on('click', '.view_student', function(e) {
            e.preventDefault();
            let extract_td = td_student.row($(this).closest('tr')).data();
            props.history.push(`/student/${extract_td.raw._id}`);
        });

        td_student.on('click', '.delete_student', function(e) {
            e.preventDefault();
            let extract_td = td_student.row($(this).closest('tr')).data();
            $(".data-message").text(`Are you sure, you want to delete, ${capitalize(extract_td.raw.firstName)}?`);
            $('#delete-data').unbind("click").click(function(){

                $("#modalConfirm").modal("hide");
                $(".modal-backdrop").remove();

                const options = {
                    position: "top-right",
                    hideAfter: 0
                };

                let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

                let data = {
                    id: extract_td.raw._id,
                }

                student("delete", data).then((result) => {
                    hide();
                    if(!result.error) {
                        reloadTable();
                        cogoToast.success("Student successfully removed.", {position: "top-right"});
                    }
                });
            });

            $("#modalConfirm").modal({ backdrop: 'static', focus: false, show: true });
        });
    }, []);

    const handleModalStudent = () => {
        setEditForm(false);
        initializeSearch();
        $('#cover_image').prop("required", true);
        $('#password').prop("required", true);
        $('#student_form').parsley();
        $("#modalStudent").modal({ backdrop: 'static', focus: false, show: true });
    }

    const initializeSearch = () => {
        let modalStudent = $('#modalStudent');
        $('#grade-select2').select2({
            placeholder: 'Select grade or type name',
            allowClear: true,
            maximumSelectionLength: 1,
            dropdownParent: modalStudent,
            ajax: {
                url: SEARCH_COLLECTION_URL,
                dataType: 'json',
                type: "POST",
                headers: {
                    "Authorization" : token,
                },
                data: function (query) {
                    return {
                        query: query,
                        collection: "grade"
                    };
                },
                processResults: function (data) {
                    return {
                        results: $.map(data, function (item) {
                            return {
                                text: capitalize(item.name),
                                id: item._id
                            }
                        })
                    };
                }
            }
        }).on('select2:select', function(e){
            $(".select2-search__field").css({display: "none"});
        }).on('select2:unselect', function(e) {
            $(".select2-search__field").css({display: "inline-block"});
        });
    }

    const S3upload = (file) => {
        const params = {
            Bucket: "app.zstudy",
            Key: `uploads/${generateId(50)}`,
            Body: file,
            ContentType: file.type
        };

        return bucket.upload(params, function (err, data) {
            if (err) return console.log({ err });
            return data;
        }).promise();
    }

    const onCloseModal = (id, form) => {
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        $(`#${form}`).parsley().reset();
        $(".modal-backdrop").remove();
    };

    const handleSubmitStudent = async(e) => {
        e.preventDefault();
        setFormLoading(true);

        let firstName = $('#firstName').val();
        let lastName = $('#lastName').val();
        let id_number = $('#id_number').val();
        let grade = $("#grade-select2").val()[0];
        let password = $('#password').val();

        if(editForm) {
            let data = {
                id: editId,
                firstName,
                lastName,
                grade,
                password
            };

            student("put", data).then((result) => {
                setFormLoading(false);

                if(!result.error) {
                    cogoToast.success("Data successfully updated.", {position: "top-right", hideAfter: 5});
                    resetForm("modalStudent", "student_form");
                    reloadTable();
                }
            });

            return false;
        }

        let file = $('#cover_image').prop('files')[0];
        let upload = await S3upload(file);

        let raw = {
            firstName,
            lastName,
            id_number,
            grade,
            password,
            cover: {
                name: file.name,
                type: file.type,
                size: file.size.toString(),
                location: upload.Location
            }
        }

        student("post", raw).then((result) => {
            setFormLoading(false);

            if(!result.error) {
                cogoToast.success("Data successfully created.", {position: "top-right", hideAfter: 5});
                resetForm("modalStudent", "student_form");
                reloadTable();
            }
        });
    }

    const resetForm = (id, form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    const reloadTable = () => {
        $('#student-table').DataTable().ajax.reload(null, false);
    };

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Students</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Students</h4>
                    </div>
                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 outline-none" onClick={handleModalStudent.bind()}><i className="wd-10 mg-r-5 fa fa-plus"></i> Add New</button>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-sm-6 col-lg-4">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Total Registered Students</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="total-students" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">-</h3>
                            </div>
                            <span className="tx-11 tx-color-02 mg-b-0 mg-t-5">Since 12th June, 2019</span>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-4 mg-t-10 mg-lg-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Total Academic</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="total-academics" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">0</h3>
                            </div>
                            <span id="date-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">-</span>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-4 mg-t-10 mg-sm-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Total Grade</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="total-grades" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">0</h3>
                            </div>
                            <span id="month-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">-</span>
                        </div>
                    </div>

                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Student</h6>
                                <div className="d-flex tx-18">
                                    <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10" onClick={() => $('#student-table').DataTable().ajax.reload()}><i className="icon ion-md-refresh"></i></a>
                                    <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10" onClick={handleModalStudent.bind()}><i className="icon ion-md-add"></i></a>
                                </div>
                            </div>
                            <div className="card-body table-responsive">
                                <table id="student-table" className="table table-hover" style={{width: "100%"}}>
                                    <thead>
                                    <tr>
                                        <th className="wd-20p">ID Number</th>
                                        <th className="wd-20p">Name</th>
                                        <th className="wd-20p">Grade</th>
                                        <th className="wd-20p">Date</th>
                                        <th className="wd-20p">Actions</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalStudent" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                    <div className="modal-content">
                        <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                            <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </a>
                            <div className="media-body">
                                <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={onCloseModal.bind(null, "modalStudent", "student_form")} aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </a>
                                <h4 className="tx-18 tx-sm-20 mg-b-2" id="edit_title">Student</h4>
                                <p className="tx-13 tx-color-02 mg-b-0" id="edit_subtitle">Fill all the required field</p>
                            </div>
                        </div>
                        <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                            <form id="student_form" className="parsley-style-1" onSubmit={handleSubmitStudent.bind()}>
                                <div id="nameWrapper" className="form-group parsley-input">
                                    <label>First Name<span className="tx-danger">*</span></label>
                                    <input id="firstName" type="text" className="form-control"
                                           placeholder="Enter school name"
                                           autoComplete="off"
                                           data-parsley-class-handler="#nameWrapper" required/>
                                </div>

                                <div id="nameWrapper" className="form-group parsley-input">
                                    <label>Last Name<span className="tx-danger">*</span></label>
                                    <input id="lastName" type="text" className="form-control"
                                           placeholder="Enter school name"
                                           autoComplete="off"
                                           data-parsley-class-handler="#nameWrapper" required/>
                                </div>

                                <div id="idWrapper" className="form-group parsley-input">
                                    <label>ID Number<span className="tx-danger">*</span></label>
                                    <input id="id_number" type="text" className="form-control"
                                           placeholder="Enter id number"
                                           autoComplete="off"
                                           data-parsley-class-handler="#idWrapper" required/>
                                </div>

                                <div id="gradeWrapper" className="form-group parsley-input">
                                    <div className="d-flex justify-content-between">
                                        <label>Grade<span className="tx-danger">*</span></label>
                                    </div>
                                    <select className="form-control select2" id="grade-select2" data-width="100%" multiple="multiple"
                                            data-parsley-class-handler="#gradeWrapper"
                                            data-parsley-errors-container="#gradeWrapper"
                                            autoComplete="off"
                                            required>
                                    </select>
                                </div>

                                <div className="form-group parsley-input">
                                    <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Cover Image</label>
                                    <input id="cover_image" type="file" className="form-control"
                                           data-parsley-filemaxmegabytes="1"
                                           data-parsley-trigger="change"
                                           data-parsley-filemimetypes="image/jpeg, image/jpg"
                                           data-parsley-max-file-size="2048576"
                                           accept="image/jpeg, image/jpg"
                                           required
                                    />
                                </div>

                                <div id="passwordWrapper" className="form-group parsley-input">
                                    <label>Password<span className="tx-danger">*</span></label>
                                    <input id="password" type="text" className="form-control"
                                           placeholder="Enter Password"
                                           autoComplete="off"
                                           data-parsley-class-handler="#passwordWrapper" required/>
                                </div>
                                {formLoading ? <button disabled className="btn btn-brand-02 btn-block"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button> : <button className="btn btn-brand-02 btn-block mt-2"><i className="ion-md-add"/> Finish</button>}
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog" aria-labelledby="confirmation" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content tx-14">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel6">Delete Confirm?</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="mg-b-0 data-message">Empty</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" id="delete-data" className="btn btn-success btn-block">Yes, Delete</button>
                            <button type="button" className="btn btn-danger btn-block mt-0" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Dashboard;
