import React, {useEffect, useState} from 'react';
import $ from "jquery";
import moment from "moment";
import {
    GET_COLLECTION_DATA,
    SEARCH_COLLECTION_URL
} from "../../api";
import 'datatables.net';
import 'datatables.net-responsive';
import cogoToast from "cogo-toast";
import {examReport, getStudent, parent} from "../../request";
import {capitalize} from "../../utils/capitalize";
import AWS from "aws-sdk";
import generateId from "../../utils/generateChar";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

let percentage = 0;
let totalFiles = 0;
let student = null;

const bucket = new AWS.S3({
    accessKeyId: "hcs",
    secretAccessKey: "HCS!2022%",
    endpoint: "https://cloudinary.zstudy.co",
    signatureVersion: 'v4',
    s3ForcePathStyle: true
});

const ViewStudent = (props) => {

    const token = localStorage.getItem('jwtToken');

    const id = props.match.params.id;
    const auth = useSelector((state) => state.auth.user);
    const [loading, setLoading] = useState(true);
    const [stateReady, setStateReady] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [parentId, setParentId] = useState(0);
    const [formLoading, setFormLoading] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState("Calculating");
    const [datatable] = useState({
        academic: false,
        parent: false
    });

    console.log(student)

    useEffect(() => {
        getStudent(id).then((response) => {
            if(!response.error) {
                // setStudent(response.payload);
                student = response.payload;
                setLoading(false);
                let td_student = $('#student-table').DataTable({
                    responsive: true,
                    language: {
                        searchPlaceholder: 'Search name',
                        sSearch: '',
                        lengthMenu: '_MENU_ items/page',
                        processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Student Data</p>'
                    },
                    searching: true,
                    lengthMenu: [[10], [10]],
                    ordering: false,
                    info: true,
                    bFilter: false,
                    processing: true,
                    pageLength: 10,
                    serverSide: true,
                    ajax: function (data, callback) {
                        $.ajax(GET_COLLECTION_DATA(auth.user.level), {
                            type: 'POST',
                            headers: {
                                "Authorization": token
                            },
                            data: {
                                query: $('.dataTables_filter input').val(),
                                collection: "students",
                                fieldname: "name",
                                filter: {
                                    academia: response.payload._id
                                },
                                pageSize: data.start,
                                populate: "grade",
                                format: 'json',
                                pageIndex: (data.length + data.start) / data.length
                            },
                            success: function (res) {
                                let array = [];
                                res.data.map((data) => {
                                    array.push({raw: data})
                                });
                                callback({
                                    recordsTotal: res.total,
                                    recordsFiltered: res.total,
                                    data: array
                                });
                            },
                            error: function(err) {
                                cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                            }
                        });
                    },
                    "columns": [
                        {"data": "raw"},
                        {"data": "raw"},
                        {"data": "raw"},
                        {"data": "raw"},
                        {"data": "raw"}
                    ],
                    "columnDefs": [
                        {
                            "render": function (data) {
                                return `${data.firstName} ${data.lastName}`;
                            },
                            "targets": 0
                        },
                        {
                            "render": function (data) {
                                return data.id_number;
                            },
                            "targets": 1
                        },
                        {
                            "render": function (data) {
                                return data.grade.map((item) => {
                                    return ` ${item.name}`
                                })
                            },
                            "targets": 2
                        },
                        {
                            "render": function (data) {
                                return moment(data.date.str).format("Do MMM, YYYY")
                            },
                            "targets": 3
                        },
                        {
                            "render": function () {
                                return `<nav class="nav nav-icon-only"><a href="#" class="nav-link delete_student"><i class="fa fa-pencil"></i> Delete</a></nav>`
                            },
                            "targets": 4
                        }
                    ]
                });

                td_student.on('click', '.delete_student', function(e) {
                    e.preventDefault();
                    let extract_td = td_student.row($(this).closest('tr')).data();
                    $(".data-message").text(`Are you sure, you want to delete, ${capitalize(extract_td.raw.firstName)}?`);
                    $('#delete-data').unbind("click").click(function(){

                        $("#modalConfirm").modal("hide");
                        $(".modal-backdrop").remove();

                        const options = {
                            position: "top-right",
                            hideAfter: 0
                        };

                        let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

                        let data = {
                            id: extract_td.raw._id,
                        }

                        student("delete", data).then((result) => {
                            hide();
                            if(!result.error) {
                                reloadTable();
                                cogoToast.success("Student successfully removed.", {position: "top-right"});
                            }
                        });
                    });

                    $("#modalConfirm").modal({ backdrop: 'static', focus: false, show: true });
                });
            }
        });
    }, []);

    const _initializeSearch = () => {
        let modalExam = $('#modalExam');

        $('#single-grade-select2').select2({
            placeholder: 'Select grade or type name',
            allowClear: true,
            dropdownParent: modalExam,
            maximumSelectionLength: 1,
            ajax: {
                url: SEARCH_COLLECTION_URL,
                dataType: 'json',
                type: "POST",
                headers: {
                    "Authorization" : token,
                },
                data: function (query) {
                    return {
                        query: query,
                        collection: "grade"
                    };
                },
                processResults: function (data) {
                    return {
                        results: $.map(data, function (item) {
                            return {
                                text: capitalize(item.name),
                                id: item._id
                            }
                        })
                    };
                }
            }
        }).on('select2:select', function(e){
            $(".select2-search__field").css({display: "none"});
        }).on('select2:unselect', function(e) {
            $(".select2-search__field").css({display: "inline-block"});
        });
    }

    const initializeSearch = () => {
        let modalParent = $('#modalParent');

        $('#parent-select2').select2({
            placeholder: 'Select parent or type name',
            allowClear: true,
            dropdownParent: modalParent,
            maximumSelectionLength: 1,
            ajax: {
                url: SEARCH_COLLECTION_URL,
                dataType: 'json',
                type: "POST",
                headers: {
                    "Authorization" : token,
                },
                data: function (query) {
                    return {
                        query: query,
                        collection: "parent"
                    };
                },
                processResults: function (data) {
                    return {
                        results: $.map(data, function (item) {
                            return {
                                text: capitalize(item.name),
                                id: item._id
                            }
                        })
                    };
                }
            }
        }).on('select2:select', function(e){
            $(".select2-search__field").css({display: "none"});
        }).on('select2:unselect', function(e) {
            $(".select2-search__field").css({display: "inline-block"});
        });
    }

    const resetForm = (id, form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    const examDatatable = () => {
        if(datatable.academic) return false;
        datatable.academic = true;
        let td_exam = $('#exam-table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Exam Report Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function (data, callback) {
                $.ajax(GET_COLLECTION_DATA(auth.user.level), {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "reports",
                        fieldname: "name",
                        filter: {
                            student: student._id
                        },
                        pageSize: data.start,
                        format: 'json',
                        populate: "meta.doc grade",
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.name;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.term;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data?.grade.name || "Not Set";
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return moment(data.date.str).format("Do MMM, YYYY");
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return `<nav class="nav nav-icon-only" id="fake">
                                    <a href=${data.meta.doc.uri} target="_blank" class="nav-link view_result"><i class="fa fa-eye"></i> View</a> <a href="#" class="nav-link delete_report"><i class="fa fa-trash-alt"></i> Delete</a>
                                </nav>`
                    },
                    "targets": 4
                }
            ]
        });

        td_exam.on('click', '.delete_report', function(e) {
            e.preventDefault();
            let extract_td = td_exam.row($(this).closest('tr')).data();
            $(".data-message").text(`Are you sure, you want to delete, ${capitalize(extract_td.raw.name)}?`);
            $('#delete-data').unbind("click").click(function(){

                $("#modalConfirm").modal("hide");
                $(".modal-backdrop").remove();

                const options = {
                    position: "top-right",
                    hideAfter: 0
                };

                let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

                let data = {
                    id: extract_td.raw._id,
                }

                examReport("delete", data).then((result) => {
                    hide();
                    if(!result.error) {
                        $('#exam-table').DataTable().ajax.reload(null, false);
                        cogoToast.success("Report successfully removed.", {position: "top-right"});
                    }
                });
            });

            $("#modalConfirm").modal({ backdrop: 'static', focus: false, show: true });
        });
    }

    const parentDatatable = () => {
        if (datatable.parent) return false;
        datatable.parent = true;
        let td_parent = $('#parent-table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Parent Report Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function (data, callback) {
                $.ajax(GET_COLLECTION_DATA(auth.user.level), {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "parents",
                        fieldname: "name",
                        filter: {
                            _id: student.parents.length ? { $in: student.parents.map((item) => item.doc) } : "635727ec13b355072db3f345"
                        },
                        pageSize: data.start,
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function (err) {
                        cogoToast.error(err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {
                            position: "top-right",
                            hideAfter: 5
                        });
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.name;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.email;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.mobile_number;
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return capitalize(student.parents.find((item) => item.doc == data._id).relation);
                    },
                    "targets": 3
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only" id="fake">
                                    <span href="#" class="nav-link"><i class="fa fa-ban"></i> No Action</span>
                                </nav>`
                    },
                    "targets": 4
                }
            ]
        });

        td_parent.on('click', '.delete_parent', function(e) {
            e.preventDefault();
            let extract_td = td_parent.row($(this).closest('tr')).data();
            $(".data-message").text(`Are you sure, you want to delete, ${capitalize(extract_td.raw.name)}?`);
            $('#delete-data').unbind("click").click(function(){

                $("#modalConfirm").modal("hide");
                $(".modal-backdrop").remove();

                const options = {
                    position: "top-right",
                    hideAfter: 0
                };

                let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

                let data = {
                    id: extract_td.raw._id,
                }

                parent("delete", data).then((result) => {
                    hide();
                    if(!result.error) {
                        $('#parent-table').DataTable().ajax.reload(null, false);
                        cogoToast.success("Parent successfully removed.", {position: "top-right"});
                    }
                });
            });

            $("#modalConfirm").modal({ backdrop: 'static', focus: false, show: true });
        });
    };

    const handleModalExam = () => {
        setEditForm(false);
        _initializeSearch();
        $('#exam_form').parsley();
        $("#modalExam").modal({ backdrop: 'static', focus: false, show: true });
    }

    const handleModalParent = () => {
        setEditForm(false);
        initializeSearch();
        $('#parent_form').parsley();
        $("#modalParent").modal({ backdrop: 'static', focus: false, show: true });
    }

    const onCloseModal = (id, form) => {
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        $(`#${form}`).parsley().reset();
        $(".modal-backdrop").remove();
    };

    const S3upload = (file) => {
        const params = {
            Bucket: "app.zstudy",
            Key: `uploads/${generateId(50)}`,
            Body: file,
            ContentType: file.type
        };

        return bucket.upload(params, function (err, data) {
            if (err) return console.log(err);
            return data;
        }).on('httpUploadProgress', function(progress) {
            let progressPercentage = Math.round(progress.loaded / progress.total * 100);
            setUploadPercentage(`Uploading ${(percentage + progressPercentage/totalFiles).toFixed(1)}%`);
        }).promise();
    }

    const handleSubmitExamReport = async(e) => {
        e.preventDefault();
        setFormLoading(true);

        let grade = $("#single-grade-select2").val()[0];
        let file = $('#document-file').prop('files')[0];

        let upload = await S3upload(file);

        let raw = {
            name: $("#name").val(),
            term: $("#term").val(),
            grade,
            year: new Date().getFullYear(),
            student: student._id,
            file: {
                name: file.name,
                type: file.type,
                size: file.size.toString(),
                location: upload.Location
            }
        }

        examReport("post", raw).then((result) => {
            setFormLoading(false);

            if(!result.error) {
                cogoToast.success("Data successfully created.", {position: "top-right", hideAfter: 5});
                resetForm("modalExam", "exam_form");
                $('#exam-table').DataTable().ajax.reload(null, false);
            }
        });
    }

    const handleParentSubmit = (e) => {
        e.preventDefault();
        setStateReady(true);

        let raw = {
            relation: $('#relation').val(),
            parent_id: $('#parent-select2').val()[0],
            student_id: student._id
        };

        parent("patch", raw).then((result) => {
            setStateReady(false);
            if(!result.error) {
                getStudent(id).then((response) => {
                    student = response.payload;
                    cogoToast.success("Data successfully created.", {position: "top-right", hideAfter: 5});
                    resetForm("modalParent", "parent_form");
                    $('#parent-table').DataTable().ajax.reload(null, false);
                })
            }
        });

    };

    const reloadTable = () => {
        $('#exam-table').DataTable().ajax.reload(null, false);
    };

    let renderButton = () => {
        if(stateReady === true) {
            return(
                <button disabled className="btn btn-brand-02 btn-block"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button>
            )
        } else {
            return editForm ? <button className="btn btn-brand-02 btn-block">Update</button> : <button className="btn btn-brand-02 btn-block">Done</button>
        }
    };

    if(!loading) {
        return (
            <div className="content-body">
                <div className="container-fluid pd-x-0">
                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                    <li className="breadcrumb-item"><a href="#">Menu</a></li>
                                    <li className="breadcrumb-item"><a href="#">Student</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Details</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                        <div className="media">
                            <div className="wd-100 ht-100 bg-ui-04 rounded d-flex align-items-center justify-content-center bg-white">{student?.photo?.uri ? <img src={student.photo.uri} className="img-fit-cover rounded-5" alt="logo"/> : <span className="link-03 lh-0 text-center"><i style={{fontSize: 50}} className="icon ion-md-camera"></i><p className="text-center tx-color-03 mt-2 tx-13">No Photo</p></span>}
                            </div>
                            <div className="media-body pd-l-25 my-auto">
                                <h5 className="mg-b-5">{capitalize(student?.firstName)} {capitalize(student?.lastName)}</h5>
                                <p className="mg-b-3 text-capitalize">
                                    <span
                                        className="tx-medium tx-color-02">{student?.grade?.name || "Not Set"}</span><br/>
                                    {student?.id_number}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row row-xs">
                        <div className="col-md-12 mg-t-10">
                            <div className="nav-wrapper mg-b-20 tx-13">
                                <div>
                                    <ul id="nav_basic" className="nav nav-line nav-fill tx-medium">
                                        <li className="nav-item"><a href="#basic" className="nav-link active" data-toggle="tab"><i className="ion-md-person"/> Student</a></li>
                                        <li className="nav-item"><a href="#exam-report" className="nav-link" data-toggle="tab" onClick={examDatatable.bind()}><i className="ion-md-film"/> Exam Report</a></li>
                                        <li className="nav-item"><a href="#parent" className="nav-link" data-toggle="tab" onClick={parentDatatable.bind()}><i className="ion-md-people"/> Parent</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="tab-content">
                                <div id="basic" className="tab-pane fade active show">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <p><strong>Student #:</strong> {student?.id_number}</p>
                                            <p><strong>Full Name:</strong> {student?.firstName} {student?.lastName}</p>
                                            <p><strong>Grade:</strong> {capitalize(student?.grade?.name) || "Not Set"}</p>
                                            <p><strong>Date Created:</strong> {moment(student?.date.str).format("Do MMM, YYYY")}</p>
                                        </div>
                                    </div>
                                </div>

                                <div id="exam-report" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div
                                                    className="card-header d-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Exam Report</h6>
                                                    <div className="d-flex tx-18">
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10" onClick={() => $('#exam-table').DataTable().ajax.reload()}><i className="icon ion-md-refresh"></i></a>
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10" onClick={handleModalExam.bind()}><i className="icon ion-md-add"></i></a>
                                                    </div>
                                                </div>
                                                <div className="card-body table-responsive">
                                                    <table id="exam-table" className="table table-hover"
                                                           style={{width: "100%"}}>
                                                        <thead>
                                                        <tr>
                                                            <th className="wd-20p">Name</th>
                                                            <th className="wd-20p">Term</th>
                                                            <th className="wd-20p">Grade</th>
                                                            <th className="wd-20p">Date</th>
                                                            <th className="wd-20p">Actions</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="parent" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Parent List</h6>
                                                    <div className="d-flex tx-18">
                                                        <Link to="#" onClick={() => $('#parent-table').DataTable().ajax.reload(null, false)} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></Link>
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10" onClick={handleModalParent.bind()}><i className="icon ion-md-add"></i></a>
                                                    </div>
                                                </div>
                                                <div className="card-body table-responsive">
                                                    <table id="parent-table" className="table table-hover" style={{width: "100%"}}>
                                                        <thead className="thead-light">
                                                        <tr>
                                                            <th className="wd-20p">Name</th>
                                                            <th className="wd-20p">Email</th>
                                                            <th className="wd-20p">Mobile Number</th>
                                                            <th className="wd-20p">Relation</th>
                                                            <th className="wd-20p">Actions</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalExam" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                            <div className="modal-content">
                                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                    <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <div className="media-body">
                                        <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={onCloseModal.bind(null, "modalExam", "exam_form")} aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </a>
                                        <h4 className="tx-18 tx-sm-20 mg-b-2" id="edit_title">Academic</h4>
                                        <p className="tx-13 tx-color-02 mg-b-0" id="edit_subtitle">Fill all the required field</p>
                                    </div>
                                </div>
                                <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                    <form id="exam_form" className="parsley-style-1"
                                          onSubmit={handleSubmitExamReport.bind()}>
                                        <div id="nameWrapper" className="form-group parsley-input">
                                            <label>Name<span className="tx-danger">*</span></label>
                                            <input id="name" type="text" className="form-control"
                                                   placeholder="Enter name e.g Mid-Term Test"
                                                   autoComplete="off"
                                                   data-parsley-class-handler="#nameWrapper" required/>
                                        </div>

                                        <div id="termWrapper" className="form-group parsley-input">
                                            <label>Term<span className="tx-danger">*</span></label>
                                            <select className="custom-select" id="term" required>
                                                <option value="" disabled selected>Select</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                            </select>
                                        </div>

                                        <div id="gradeWrapper" className="form-group parsley-input">
                                            <div className="d-flex justify-content-between">
                                                <label>Grade<span className="tx-danger">*</span></label>
                                            </div>
                                            <select className="form-control" id="single-grade-select2" data-width="100%"
                                                    multiple="multiple"
                                                    data-parsley-class-handler="#gradeWrapper"
                                                    data-parsley-errors-container="#gradeWrapper"
                                                    autoComplete="off"
                                                    required>
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Upload
                                                File</label>
                                            <input id="document-file" type="file" className="form-control"
                                                   data-parsley-filemaxmegabytes="1"
                                                   data-parsley-trigger="change"
                                                   data-parsley-filemimetypes="document/pdf"
                                                   accept="application/pdf"
                                                   required
                                            />
                                        </div>
                                        {formLoading ? <button disabled className="btn btn-brand-02 btn-block"><span
                                                className="spinner-border spinner-border-sm mg-r-10" role="status"
                                                aria-hidden="true"/>Please Wait</button> :
                                            <button className="btn btn-brand-02 btn-block mt-2"><i
                                                className="ion-md-add"/> Finish</button>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalParent" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                            <div className="modal-content">
                                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                    <a href="#" role="button" className="close pos-absolute t-15 r-15"
                                       data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <div className="media-body"><a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={onCloseModal.bind(null, "modalParent", "parent_form")} aria-label="Close"><span aria-hidden="true">×</span></a>
                                        <h4 className="tx-18 tx-sm-20 mg-b-2">{editForm ? "Edit Parent" : "Assign Parent"}</h4>
                                        <p className="tx-13 tx-color-02 mg-b-0">{editForm ? "Edit" : "Create"} user to have access to student information</p></div>
                                </div>
                                <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                    <form id="parent_form" className="parsley-style-1" data-parsley-validate="true"
                                          noValidate onSubmit={handleParentSubmit.bind()}>
                                        <div id="parentWrapper" className="form-group parsley-input">
                                            <div className="d-flex justify-content-between">
                                                <label>Parent<span className="tx-danger">*</span></label>
                                            </div>
                                            <select className="form-control" id="parent-select2" data-width="100%"
                                                    multiple="multiple"
                                                    data-parsley-class-handler="#parentWrapper"
                                                    data-parsley-errors-container="#parentWrapper"
                                                    autoComplete="off"
                                                    required>
                                            </select>
                                        </div>

                                        <div id="termWrapper" className="form-group parsley-input">
                                            <label>Relation<span className="tx-danger">*</span></label>
                                            <select className="custom-select" id="relation" required>
                                                <option value="" disabled selected>Select</option>
                                                <option value="father">Father</option>
                                                <option value="mother">Mother</option>
                                                <option value="guardian">Guardian</option>
                                            </select>
                                        </div>

                                        {renderButton()}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog"
                         aria-labelledby="confirmation" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                            <div className="modal-content tx-14">
                                <div className="modal-header">
                                    <h6 className="modal-title" id="exampleModalLabel6">Delete Confirm?</h6>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p className="mg-b-0 data-message">Empty</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" id="delete-data" className="btn btn-success btn-block">Yes,
                                        Delete
                                    </button>
                                    <button type="button" className="btn btn-danger btn-block mt-0"
                                            data-dismiss="modal">Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="content-body">
            <div className="container d-flex justify-content-center ht-100p">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <p className="mg-t-10">Collecting Data</p>
                </div>
            </div>
        </div>
    )
};

export default ViewStudent;
