import React from 'react';

const Robotic = () => {
    return (
        <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
            <iframe
                src="https://code.irobot.com"
                style={{ width: '100%', height: '100%', border: 'none' }}
                title="Robotic Code"
            />
        </div>
    );
};

export default Robotic;
