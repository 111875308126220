import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import $ from "jquery";
import feather from "feather-icons";
import {logoutUser, getCategory} from '../../request';
import PerfectScrollbar from 'perfect-scrollbar';
import {useDispatch, useSelector} from "react-redux";
import {capitalize} from "../../utils/capitalize";

const Header = () => {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth.user);

    const location = useLocation();
    const { pathname } = location;

    const onLogoutClick = (event) => {
        event.preventDefault();
        dispatch(logoutUser());
    };

    useEffect(() => {
        $(function () {
            'use strict';
            feather.replace();

            const asideBody = new PerfectScrollbar('.aside-body', {
                suppressScrollX: true
            });

            if($('.aside-backdrop').length === 0) {
                $('body').append('<div class="aside-backdrop"></div>');
            }

            var mql = window.matchMedia('(min-width:992px) and (max-width: 1199px)');

            function doMinimize(e) {
                if (e.matches) {
                    $('.aside').addClass('minimize');
                } else {
                    $('.aside').removeClass('minimize');
                }

                asideBody.update()
            }

            mql.addListener(doMinimize);
            doMinimize(mql);

            $('.aside-menu-link').on('click', function(e){
                e.preventDefault();

                if(window.matchMedia('(min-width: 992px)').matches) {
                    $(this).closest('.aside').toggleClass('minimize');
                } else {

                    $('body').toggleClass('show-aside');
                }

                asideBody.update()
            });

            $('.nav-aside .with-sub').on('click', '.nav-link', function(e){
                e.preventDefault();

                $(this).parent().siblings().removeClass('show');
                $(this).parent().toggleClass('show');

                asideBody.update()
            });

            $('body').on('mouseenter', '.minimize .aside-body', function(e){
                $(this).parent().addClass('maximize');
            });

            $('body').on('mouseleave', '.minimize .aside-body', function(e){
                $(this).parent().removeClass('maximize');

                asideBody.update()
            });

            $('body').on('click', '.aside-backdrop', function(e){
                $('body').removeClass('show-aside');
            })
        });
    }, []);

    return (
        <aside className="aside aside-fixed">
            <div className="aside-header">
                {auth.user.level === 0 ? <a href="#" className="aside-logo">ZS<span>tudy Academia</span></a> : <a href="#" className="aside-logo">ZS<span>tudy Teacher</span></a>}
                <a href="#" className="aside-menu-link">
                    <i data-feather="menu"></i>
                    <i data-feather="x"></i>
                </a>
            </div>
            <div className="aside-body">
                <div className="aside-loggedin">
                    <div className="aside-loggedin-user">
                        <a href="#loggedinMenu" className="d-flex align-items-center justify-content-between mg-b-2"
                           data-toggle="collapse">
                            <h6 className="tx-semibold mg-b-0">{auth.user.level === 0 ? capitalize(auth.user.name) : capitalize(auth.user.academia.name)}</h6>
                            <i data-feather="chevron-down"></i>
                        </a>
                        <p className="tx-color-03 tx-12 mg-b-0">{auth.user.level === 0 ? capitalize(auth.user.role) : "Teacher"}</p>
                    </div>
                    <div className="collapse" id="loggedinMenu">
                        <ul className="nav nav-aside mg-b-0">
                            <li className="nav-item"><Link to="#" onClick={onLogoutClick.bind()} className="nav-link"><i data-feather="log-out"></i>
                                <span>Sign Out</span></Link></li>
                        </ul>
                    </div>
                </div>

                {auth.user.level === 0 ? <ul className="nav nav-aside">
                    <li className="nav-label">General</li>
                    <li className={`nav-item ${pathname === '/dashboard' ? 'active' : ''}`}><Link to="/dashboard" className="nav-link"><i data-feather="bar-chart"></i> <span>Student</span></Link></li>
                    <li className={`nav-item ${pathname === '/academic' ? 'active' : ''}`}><Link to="/academic" className="nav-link"><i data-feather="video"></i> <span>Course</span></Link></li>
                    <li className={`nav-item ${pathname === '/teacher' ? 'active' : ''}`}><Link to="/teacher" className="nav-link"><i data-feather="user"></i> <span>Teacher</span></Link></li>
                    <li className={`nav-item ${pathname === '/parent' ? 'active' : ''}`}><Link to="/parent" className="nav-link"><i data-feather="columns"></i> <span>Parent</span></Link></li>
                    <li className={`nav-item with-sub ${pathname.startsWith('/bus') ? 'active show' : ''}`}>
                        <a href="#" className="nav-link">
                            <i data-feather="map"></i> <span>Bus Register</span>
                        </a>
                        <ul>
                            <li className={pathname === '/bus/patron' ? 'active' : ''}>
                                <Link to="/bus/patron">Patron</Link>
                            </li>
                            <li className={pathname === '/bus/vehicle' ? 'active' : ''}>
                                <Link to="/bus/vehicle">Bus</Link>
                            </li>
                            <li className={pathname === '/bus/driver' ? 'active' : ''}>
                                <Link to="/bus/driver">Driver</Link>
                            </li>
                            <li className={pathname === '/bus/route' ? 'active' : ''}>
                                <Link to="/bus/route">Report</Link>
                            </li>
                            <li className={pathname === '/bus/zone' ? 'active' : ''}>
                                <Link to="/bus/zone">Zone</Link>
                            </li>
                        </ul>
                    </li>
                    <li className={`nav-item ${pathname === '/grade' ? 'active' : ''}`}><Link to="/grade"
                                                                                              className="nav-link"><i
                        data-feather="sliders"></i> <span>Grade</span></Link></li>
                    <li className={`nav-item ${pathname === '/virtual' ? 'active' : ''}`}><Link to="/virtual" className="nav-link"><i data-feather="sliders"></i> <span>Virtual Lab</span></Link></li>
                </ul> : <ul className="nav nav-aside">
                    <li className="nav-label">General</li>
                    <li className={`nav-item ${pathname === '/dashboard' ? 'active' : ''}`}><Link to="/dashboard" className="nav-link"><i data-feather="bar-chart"></i> <span>Student</span></Link></li>
                    <li className={`nav-item ${pathname === '/academic' ? 'active' : ''}`}><Link to="/academic" className="nav-link"><i data-feather="video"></i> <span>Course</span></Link></li>
                    <li className={`nav-item ${pathname === '/virtual' ? 'active' : ''}`}><Link to="/virtual" className="nav-link"><i data-feather="sliders"></i> <span>Virtual Lab</span></Link></li>
                </ul>}
            </div>
        </aside>
    );
};

export default Header;
