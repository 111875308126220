import React, {useEffect, useState} from 'react';
import {GET_COLLECTION_DATA, SEARCH_COLLECTION_URL} from '../../api';
import 'datatables.net';
import 'datatables.net-responsive';
import 'datatables.net-responsive-dt';
import 'datatables.net-editor';
import 'components-jqueryui';
import $ from "jquery";
import 'select2';
import {Link} from "react-router-dom";
import cogoToast from "cogo-toast";
import {zone} from "../../request";
import {capitalize} from "../../utils/capitalize";
import EditGMap from "../../components/map/EditMap";
import MarkedGMap from "../../components/map/MarkedGMap";
import GMap from "../../components/map/GMap";
import {areAllPointsInsideMainZone, doPolygonsIntersect} from "../../utils/geometryUtils";
import moment from "moment/moment";
import {useSelector} from "react-redux";

let coordinates = null;
let arrayZone = [];
let selectedZone = null;
let mainZone = null;

const Zone = () => {

    document.title = "Zone";

    const auth = useSelector((state) => state.auth.user);
    const [stateReady, setStateReady] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [geometry, setGeometry] = useState([]);
    const [editPolygon, setEditPolygon] = useState(false);
    const [openMap, setOpenMap] = useState(false);
    const [contentId, setContentId] = useState(null);
    const [editId, setEditId] = useState(null);
    const [zones, setZones] = useState([]);
    const [checked, setChecked] = useState(false);
    const [zoneLoading, setZoneLoading] = useState(false);
    const [_zoneLoading, _setZoneLoading] = useState(false);

    const token = localStorage.getItem('jwtToken');

    useEffect(() => {
        let table = $('#zone_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Zone Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function (data, callback) {
                $.ajax(GET_COLLECTION_DATA(auth.user.level), {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "zones",
                        fieldname: "name",
                        pageSize: data.start,
                        sort: {name: 1},
                        format: 'json',
                        populate: 'patron bus driver',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });

                        arrayZone = array;

                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function (err) {
                        cogoToast.error(err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {
                            position: "top-right",
                            hideAfter: 5
                        });
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.name;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `${data.patron.first_name} ${data.patron.last_name}`;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return `${data.bus.make} - ${data.bus.number_plate}`;
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return moment(data.createdAt).format("Do MMM, YYYY");
                    },
                    "targets": 3
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link edit_zone"><i class="fa fa-edit"></i> Edit</a><a href="#" class="nav-link delete_zone"><i class="fa fa-trash-alt"></i> Delete</a></nav>`
                    },
                    "targets": 4
                }
            ]
        });

        table.on('click', '.edit_zone', function(e) {
            e.preventDefault();
            setEditPolygon(true);

            let extract_td = table.row($(this).closest('tr')).data();
            setContentId(extract_td.raw._id);

            let patron_select = $("#patron-select2");
            let bus_select = $("#bus-select2");
            let driver_select = $("#driver-select2");

            $("#zone-name").val(extract_td.raw.name);
            setEditForm(true);

            initializePatronSearch();
            initializeBusSearch();
            initializeDriverSearch();

            setGeometry(convertCoordinates(extract_td.raw));
            coordinates = convertCoordinates(extract_td.raw);

            let $option = $("<option selected></option>").val(extract_td.raw.patron._id).text(extract_td.raw.patron.first_name);
            patron_select.append($option).trigger('change');

            let $_option = $("<option selected></option>").val(extract_td.raw.bus._id).text(extract_td.raw.bus.number_plate);
            bus_select.append($_option).trigger('change');

            let $__option = $("<option selected></option>").val(extract_td.raw.driver._id).text(extract_td.raw.driver.first_name);
            driver_select.append($__option).trigger('change');

            $('#zone_form').parsley();

            $("#modalZone").modal({ backdrop: 'static', focus: false, show: true });
            setOpenMap(true);
        });

        table.on('click', '.delete_zone', function (e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
            $(".data-message").text(`Are you sure, you want to delete ${extract_td.raw.name}, this data?`);
            $('#delete-data').unbind("click").click(function () {

                $("#modalConfirm").modal("hide");
                $(".modal-backdrop").remove();

                const options = {
                    position: "top-right",
                    hideAfter: 0
                };

                let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

                let data = {
                    id: extract_td.raw._id,
                }

                zone("delete", data).then((result) => {
                    hide();
                    if (!result.error) {
                        $('#zone_table').DataTable().ajax.reload();
                        cogoToast.success("Zone successfully removed.", {position: "top-right"});
                    }
                });
            });

            $("#modalConfirm").modal({backdrop: 'static', focus: false, show: true});
        });
    }, []);

    const handleZoneSubmit = (e) => {
        e.preventDefault();

        if(coordinates === null) return cogoToast.error("Please, draw a polygon shape on the map.", {position: "top-right", hideAfter: 5});

        setStateReady(true);

        let data = {
            name: $("#zone-name").val(),
            patron: $('#patron-select2').val()[0],
            bus: $('#bus-select2').val()[0],
            driver: $('#driver-select2').val()[0],
            geometry: coordinates
        };

        if(editForm) {
            data = {
                id: contentId,
                action: "self",
                zone: data
            };

            zone("put", data).then((result) => {
                setStateReady(false);

                if(!result.error) {
                    cogoToast.success("Data successfully updated.", {position: "top-right", hideAfter: 5});
                    resetForm("modalZone", "zone_form");
                    $('#zone_table').DataTable().ajax.reload();
                }
            });

            return false;
        }

        zone("post", data).then((result) => {
            setStateReady(false);

            if(!result.error) {
                cogoToast.success("Zone successfully created.", {position: "top-right", hideAfter: 5});
                resetForm("modalZone", "zone_form");
                $('#zone_table').DataTable().ajax.reload();
            }
        });
    }

    const initializePatronSearch = () => {
        let modalZone = $('#modalZone');
        $('#patron-select2').select2({
            placeholder: 'Select Patron',
            allowClear: true,
            width:"100%",
            maximumSelectionLength: 1,
            dropdownParent: modalZone,
            ajax: {
                url: SEARCH_COLLECTION_URL,
                dataType: 'json',
                type: "POST",
                headers: {
                    "Authorization": token,
                },
                data: function(query) {
                    return {
                        query: query,
                        collection: "patron"
                    };
                },
                processResults: function(data) {
                    return {
                        results: $.map(data, function(item) {
                            console.log(item);
                            return {
                                text: capitalize(item.first_name + ' ' + item.last_name),
                                id: item._id
                            }
                        })
                    };
                }
            }
        }).on('select2:select', function(e){
            $(".select2-search__field").css({display: "none"});
        }).on('select2:unselect', function(e) {
            $(".select2-search__field").css({display: "inline-block"});
        });
    }

    const initializeBusSearch = () => {
        let modalZone = $('#modalZone');
        $('#bus-select2').select2({
            placeholder: 'Select Bus',
            allowClear: true,
            width:"100%",
            maximumSelectionLength: 1,
            dropdownParent: modalZone,
            ajax: {
                url: SEARCH_COLLECTION_URL,
                dataType: 'json',
                type: "POST",
                headers: {
                    "Authorization": token,
                },
                data: function(query) {
                    return {
                        query: query,
                        collection: "bus"
                    };
                },
                processResults: function(data) {
                    return {
                        results: $.map(data, function(item) {
                            return {
                                text: capitalize(item.number_plate),
                                id: item._id
                            }
                        })
                    };
                }
            }
        }).on('select2:select', function(e){
            $(".select2-search__field").css({display: "none"});
        }).on('select2:unselect', function(e) {
            $(".select2-search__field").css({display: "inline-block"});
        });
    }

    const initializeDriverSearch = () => {
        let modalZone = $('#modalZone');
        $('#driver-select2').select2({
            placeholder: 'Select Bus',
            allowClear: true,
            width:"100%",
            maximumSelectionLength: 1,
            dropdownParent: modalZone,
            ajax: {
                url: SEARCH_COLLECTION_URL,
                dataType: 'json',
                type: "POST",
                headers: {
                    "Authorization": token,
                },
                data: function(query) {
                    return {
                        query: query,
                        collection: "driver"
                    };
                },
                processResults: function(data) {
                    return {
                        results: $.map(data, function(item) {
                            return {
                                text: capitalize(item.first_name + ' ' + item.last_name),
                                id: item._id
                            }
                        })
                    };
                }
            }
        }).on('select2:select', function(e){
            $(".select2-search__field").css({display: "none"});
        }).on('select2:unselect', function(e) {
            $(".select2-search__field").css({display: "inline-block"});
        });
    }

    const modalZoneForm = () => {
        setEditForm(false);

        $('#zone_form').parsley();
        initializePatronSearch();
        initializeBusSearch();
        initializeDriverSearch();
        setOpenMap(true);
        $("#modalZone").modal({ backdrop: 'static', focus: false, show: true });
    }

    const resetForm = (id, form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        coordinates = null;
        setOpenMap(false);
        setChecked(false);
        setEditPolygon(false);
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    const convertCoordinates = (document) => {
        const coordinates = document.geometry.coordinates[0];
        return coordinates.map(coord => ({
            lat: coord[0],
            lng: coord[1]
        }));
    };

    const reloadTable = () => {
        $('#zone_table').DataTable().ajax.reload(null, false);
    };

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Zone</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Zone</h4>
                    </div>
                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5 outline-none" onClick={modalZoneForm.bind()}><i className="wd-10 mg-r-5 fa fa-plus"></i> Add New</button>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Zone List</h6>
                                <div className="d-flex tx-18">
                                    <Link to="#" onClick={reloadTable.bind()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></Link>
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="zone_table" className="table" style={{width: "100%"}}>
                                    <thead>
                                    <tr>
                                        <th className="wd-20p">Name</th>
                                        <th className="wd-20p">Patron</th>
                                        <th className="wd-20p">Bus</th>
                                        <th className="wd-20p">Date Created</th>
                                        <th className="wd-20p">Action</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalZone" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </a>
                                <div className="media-body"><a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={resetForm.bind(null, "modalZone", "zone_form")} aria-label="Close"><span aria-hidden="true">×</span></a>
                                    <h4 className="tx-18 tx-sm-20 mg-b-2">{editForm ? "Edit Zone" : "Create Zone"}</h4>
                                    <p className="tx-13 tx-color-02 mg-b-0">{editForm ? "Edit" : "Create"} zone information.</p>
                                </div>
                            </div>
                            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                <form id="zone_form" className="parsley-style-1" onSubmit={handleZoneSubmit.bind()}>
                                    <div id="zoneNameWrapper" className="form-group parsley-input">
                                        <label>Name<span className="tx-danger">*</span></label>
                                        <input id="zone-name" type="text" className="form-control"
                                               placeholder="Enter zone name"
                                               autoComplete="none"
                                               data-parsley-class-handler="#zoneNameWrapper"
                                               required
                                        />
                                    </div>

                                    <div id="patronWrapper" className="form-group parsley-input">
                                        <div className="d-flex justify-content-between">
                                            <label>Patron<span className="tx-danger">*</span></label>
                                        </div>
                                        <select className="form-control" id="patron-select2" data-width="100%"
                                                multiple="multiple"
                                                data-parsley-class-handler="#patronWrapper"
                                                data-parsley-errors-container="#patronWrapper"
                                                autoComplete="off"
                                                required>
                                        </select>
                                    </div>

                                    <div id="buseWrapper" className="form-group parsley-input">
                                        <div className="d-flex justify-content-between">
                                            <label>Bus<span className="tx-danger">*</span></label>
                                        </div>
                                        <select className="form-control" id="bus-select2" data-width="100%"
                                                multiple="multiple"
                                                data-parsley-class-handler="#buseWrapper"
                                                data-parsley-errors-container="#buseWrapper"
                                                autoComplete="off"
                                                required>
                                        </select>
                                    </div>

                                    <div id="driverWrapper" className="form-group parsley-input">
                                        <div className="d-flex justify-content-between">
                                            <label>Driver<span className="tx-danger">*</span></label>
                                        </div>
                                        <select className="form-control" id="driver-select2" data-width="100%"
                                                multiple="multiple"
                                                data-parsley-class-handler="#driverWrapper"
                                                data-parsley-errors-container="#driverWrapper"
                                                autoComplete="off"
                                                required>
                                        </select>
                                    </div>

                                    <div className="mg-b-20">
                                        {openMap ? editPolygon ?
                                            <EditGMap polygons={checked ? zones : []} geometry={geometry}
                                                      onCoordinatesChange={(data) => coordinates = data}/> :
                                            <GMap polygons={checked ? zones : []}
                                                  onCoordinatesChange={(data) => coordinates = data[0]}/> : null}
                                    </div>

                                    {stateReady ? <button disabled className="btn btn-success btn-block"><span
                                        className="spinner-border spinner-border-sm mg-r-10" role="status"
                                        aria-hidden="true"/>Please Wait</button> : <button
                                        className="btn btn-success btn-block">{editForm ? "Update Zone" : "Add Zone"}</button>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalViewZone" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal"
                                   aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </a>
                                <div className="media-body"><a href="#" role="button"
                                                               className="close pos-absolute t-15 r-15"
                                                               data-dismiss="modal" onClick={resetForm.bind(null, "modalZone", "zone_form")} aria-label="Close"><span aria-hidden="true">×</span></a>
                                    <h4 className="tx-18 tx-sm-20 mg-b-2">View Marked Zone</h4>
                                    <p className="tx-13 tx-color-02 mg-b-0">Marked zone information.</p>
                                </div>
                            </div>
                            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                <div className="mg-b-20">
                                    {openMap ? <MarkedGMap polygons={zones}/> : null }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog" aria-labelledby="confirmation"
                     aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div className="modal-content tx-14">
                            <div className="modal-header">
                                <h6 className="modal-title" id="exampleModalLabel6">Delete Confirm?</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="mg-b-0 data-message">Empty</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" id="delete-data" className="btn btn-success btn-block">Yes, Delete
                                </button>
                                <button type="button" className="btn btn-danger btn-block mt-0" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Zone;
