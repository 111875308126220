import React, {useEffect, useState} from 'react';
import {GET_COLLECTION_DATA, SEARCH_COLLECTION_URL} from '../../api';
import 'datatables.net';
import 'datatables.net-responsive';
import $ from "jquery";
import cogoToast from "cogo-toast";
import {teacher} from "../../request";
import {capitalize} from "../../utils/capitalize";
import {useSelector} from "react-redux";

const Teacher = () => {

    document.title = "Teacher";

    const auth = useSelector((state) => state.auth.user);

    const token = localStorage.getItem('jwtToken');
    const [teacherId, setTeacherId] = useState(0);
    const [formLoading, setFormLoading] = useState(false);
    const [cover_image, set_cover_image] = useState(null);
    const [editForm, setEditForm] = useState(false);

    useEffect(() => {
        let td_teacher = $('#td-teacher').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Please Wait</p>'
            },
            "searching": true,
            "lengthMenu": [[10], [10]],
            ordering: false,
            searchDelay: 650,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_COLLECTION_DATA(auth.user.level), {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "teachers",
                        fieldname: "name",
                        pageSize: data.start,
                        filter: {
                            academia: auth.user.sub
                        },
                        format: 'json',
                        populate: 'cover',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success : function(res) {
                        let array = [];
                        res.data.map((data) => {array.push({raw: data})});
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.name;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.email;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.mobile_number
                    },
                    "targets": 2
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only">
                                    <a href="#" class="nav-link edit_teacher"><i class="fa fa-pencil-alt"></i> Edit</a><a href="#" class="nav-link delete_teacher"><i class="fa fa-trash-alt"></i> Delete</a>
                                </nav>`
                    },
                    "targets": 3
                }
            ]
        });

        td_teacher.on('click', '.delete_teacher', function(e) {
            e.preventDefault();
            let extract_td = td_teacher.row($(this).closest('tr')).data();
            $(".data-message").text(`Are you sure, you want to delete, ${capitalize(extract_td.raw.name)}?`);
            $('#delete-data').unbind("click").click(function(){

                $("#modalConfirm").modal("hide");
                $(".modal-backdrop").remove();

                const options = {
                    position: "top-right",
                    hideAfter: 0
                };

                let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

                let data = {
                    id: extract_td.raw._id,
                }

                teacher("delete", data).then((result) => {
                    hide();
                    if(!result.error) {
                        reloadTable();
                        cogoToast.success("Grade successfully removed.", {position: "top-right"});
                    }
                });
            });

            $("#modalConfirm").modal({ backdrop: 'static', focus: false, show: true });
        });

        td_teacher.on('click', '.edit_teacher', function(e) {
            e.preventDefault();
            let extract_td = td_teacher.row($(this).closest('tr')).data();
            setTeacherId(extract_td.raw._id);
            setEditForm(true);

            $("#name").val(extract_td.raw.name);
            $("#email").val(extract_td.raw.email);
            $("#mobile").val(extract_td.raw.mobile_number);
            $("#address").val(extract_td.raw.address);

            $("#password").prop("required", false);

            $("#modalTeacher").modal({backdrop: 'static', keyboard: false}, "show");
        });
    }, []);

    const handleSubmit = async(e) => {
        e.preventDefault();
        setFormLoading(true);

        let name = $('#name').val();
        let email = $('#email').val();
        let mobile_number = $('#mobile').val();
        let password = $('#password').val();
        let address = $('#address').val();

        if(editForm) {
            let raw = {
                id: teacherId,
                name,
                email,
                mobile_number,
                address,
                password
            }

            teacher("put", raw).then((result) => {
                setFormLoading(false);

                if(!result.error) {
                    cogoToast.success("Data successfully updated.", {position: "top-right", hideAfter: 5});
                    resetForm("modalTeacher", "teacher_form");
                    reloadTable();
                }
            });

            return false;
        }

        let raw = {
            name,
            email,
            mobile_number,
            address,
            password,
            academia: auth.user.sub
        }

        teacher("post", raw).then((result) => {
            setFormLoading(false);

            if(!result.error) {
                cogoToast.success("Data successfully created.", {position: "top-right", hideAfter: 5});
                resetForm("modalTeacher", "teacher_form");
                reloadTable();
            }
        });
    }

    const initializeSearch = () => {
        let modalTeacher = $('#modalTeacher');
        $('#teacher-select2').select2({
            placeholder: 'Select teacher or type name',
            allowClear: true,
            maximumSelectionLength: 1,
            dropdownParent: modalTeacher,
            ajax: {
                url: SEARCH_COLLECTION_URL,
                dataType: 'json',
                type: "POST",
                headers: {
                    "Authorization" : token,
                },
                data: function (query) {
                    return {
                        query: query,
                        collection: "teacher"
                    };
                },
                processResults: function (data) {
                    return {
                        results: $.map(data, function (item) {
                            return {
                                text: capitalize(item.name),
                                id: item._id
                            }
                        })
                    };
                }
            }
        }).on('select2:select', function(e){
            $(".select2-search__field").css({display: "none"});
        }).on('select2:unselect', function(e) {
            $(".select2-search__field").css({display: "inline-block"});
        });
    }

    const handleTeacherForm = (e) => {
        e.preventDefault();
        setEditForm(false);
        initializeSearch();
        $("#password").prop("required", true);
        $('#teacher_form').parsley();
        $("#modalTeacher").modal({backdrop: 'static', keyboard: false}, "show");
    }

    const resetForm = (id, form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    const onCloseModal = (id, form) => {
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        $(`#${form}`).parsley().reset();
        $(".modal-backdrop").remove();
    };

    const reloadTable = () => {
        $('#td-teacher').DataTable().ajax.reload(null, false);
    };

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Teacher</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Teacher</h4>
                    </div>
                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 outline-none" onClick={handleTeacherForm.bind()}><i className="wd-10 mg-r-5 fa fa-plus"></i> Add New</button>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Total Teachers</h6>
                                <div className="d-flex tx-18">
                                    <a href="javascript:void(0)" className="link-03 lh-0" onClick={handleTeacherForm.bind()}><i className="icon ion-md-add"></i></a>
                                    <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10" onClick={() => $('#td-teacher').DataTable().ajax.reload()}><i className="icon ion-md-refresh"></i></a>
                                </div>
                            </div>
                            <div className="card-body table-responsive">
                                <table id="td-teacher" className="table table-hover" style={{width: "100%"}}>
                                    <thead className="thead-light">
                                    <tr>
                                        <th className="wd-25p">Name</th>
                                        <th className="wd-25p">Email</th>
                                        <th className="wd-25p">Mobile Number</th>
                                        <th className="wd-25p">Action</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalTeacher" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                    <div className="modal-content">
                        <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                            <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </a>
                            <div className="media-body">
                                <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={onCloseModal.bind(null, "modalTeacher", "teacher_form")} aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </a>
                                <h4 className="tx-18 tx-sm-20 mg-b-2" id="edit_title">Teacher</h4>
                                <p className="tx-13 tx-color-02 mg-b-0" id="edit_subtitle">Fill all the required field</p>
                            </div>
                        </div>
                        <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                            <form id="teacher_form" className="parsley-style-1" onSubmit={handleSubmit.bind()}>
                                <div id="nameWrapper" className="form-group parsley-input">
                                    <label>Name<span className="tx-danger">*</span></label>
                                    <input id="name" type="text" className="form-control"
                                           placeholder="Enter school name"
                                           autoComplete="off"
                                           data-parsley-class-handler="#nameWrapper" required/>
                                </div>

                                <div id="emailWrapper" className="form-group parsley-input">
                                    <label>Email Address<span className="tx-danger">*</span></label>
                                    <input id="email" type="email" className="form-control"
                                           placeholder="Enter email"
                                           autoComplete="off"
                                           data-parsley-class-handler="#emailWrapper" required/>
                                </div>

                                <div id="mobileWrapper" className="form-group parsley-input">
                                    <label>Mobile Number<span className="tx-danger">*</span></label>
                                    <input id="mobile" type="number" className="form-control"
                                           placeholder="Enter mobile number"
                                           autoComplete="off"
                                           data-parsley-class-handler="#mobileWrapper" required/>
                                </div>

                                <div id="addressWrapper" className="form-group parsley-input">
                                    <label>Physical Address<span className="tx-danger">*</span></label>
                                    <input id="address" type="text" className="form-control"
                                           placeholder="Enter physical address"
                                           autoComplete="off"
                                           data-parsley-class-handler="#addressWrapper" required/>
                                </div>

                                <div id="passwordWrapper" className="form-group parsley-input">
                                    <label>Password<span className="tx-danger">*</span></label>
                                    <input id="password" type="text" className="form-control"
                                           placeholder="Enter password"
                                           autoComplete="off"
                                           data-parsley-class-handler="#passwordWrapper"/>
                                </div>

                                {formLoading ? <button disabled className="btn btn-brand-02 btn-block"><span className="spinner-border spinner-border-sm mg-r-10" role="status"
                                        aria-hidden="true"/>Please Wait</button> :
                                    <button className="btn btn-brand-02 btn-block mt-2"><i
                                        className="ion-md-add"/> Finish</button>}
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog" aria-labelledby="confirmation"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content tx-14">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel6">Delete Confirm?</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="mg-b-0 data-message">Empty</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" id="delete-data" className="btn btn-success btn-block">Yes, Delete</button>
                            <button type="button" className="btn btn-danger btn-block mt-0" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Teacher;
