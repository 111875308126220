import React, { useCallback, useEffect, useRef, useState } from "react";
import { GoogleMap, Polyline, Marker, InfoWindow, useLoadScript } from "@react-google-maps/api";
import mapStyle from "../../data/map.json";

const MAX_WAYPOINTS = 20; // Google API limit is 23, keep some buffer.

const TrackMap = ({ coordinates, pinpoints }) => {
    const mapRef = useRef(null);
    const [alignedPath, setAlignedPath] = useState([]);
    const [selectedPoint, setSelectedPoint] = useState(null);
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
        libraries: ["places", "geometry"],
    });

    // Function to reduce waypoints dynamically
    const getReducedWaypoints = (coords) => {
        if (coords.length <= MAX_WAYPOINTS + 2) return coords.slice(1, -1);

        // Select evenly distributed waypoints
        const step = Math.floor(coords.length / MAX_WAYPOINTS);
        return coords.filter((_, index) => index % step === 0).slice(1, -1);
    };

    const fetchRoute = async () => {
        if (!isLoaded || coordinates.length < 2) return;

        const directionsService = new window.google.maps.DirectionsService();
        console.log("Fetching route with coordinates:", coordinates);

        const waypoints = getReducedWaypoints(coordinates).map(coord => ({
            location: new window.google.maps.LatLng(coord.lat, coord.lng),
            stopover: false,
        }));

        const request = {
            origin: coordinates[0],
            destination: coordinates[coordinates.length - 1],
            waypoints: waypoints,
            travelMode: window.google.maps.TravelMode.DRIVING,
        };

        directionsService.route(request, (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
                const newPath = result.routes[0].overview_path.map(point => ({
                    lat: point.lat(),
                    lng: point.lng(),
                }));
                setAlignedPath(newPath);
                console.log("Polyline path updated:", newPath);
            } else {
                console.error("Directions request failed:", status);
            }
        });
    };

    useEffect(() => {
        if (isLoaded) fetchRoute();
    }, [isLoaded, coordinates]);

    const onLoad = useCallback((map) => {
        mapRef.current = map;
        if (coordinates.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            coordinates.forEach(coord => bounds.extend(coord));
            map.fitBounds(bounds);
        }
    }, [coordinates]);

    return (
        <>
            {!isLoaded ? (
                <h6 style={{ marginTop: 40 }}>Loading Map...</h6>
            ) : (
                <div>
                    <GoogleMap
                        mapContainerClassName="map-container"
                        onLoad={onLoad}
                        zoom={10}
                        options={{ styles: mapStyle }}
                    >
                        {alignedPath.length > 1 && (
                            <>
                                <Polyline
                                    path={alignedPath}
                                    options={{
                                        strokeColor: "#F44A53",
                                        strokeOpacity: 0.8,
                                        strokeWeight: 6,
                                        geodesic: true,
                                    }}
                                />
                                <Marker
                                    position={coordinates[0]}
                                    icon={{
                                        url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
                                        scaledSize: new window.google.maps.Size(40, 40),
                                    }}
                                />
                                <Marker
                                    position={coordinates[coordinates.length - 1]}
                                    icon={{
                                        url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                                        scaledSize: new window.google.maps.Size(40, 40),
                                    }}
                                />
                            </>
                        )}

                        {pinpoints?.map((point) => (
                            <Marker
                                key={point.check._id}
                                position={{
                                    lat: point.check.location.coordinates[0],
                                    lng: point.check.location.coordinates[1],
                                }}
                                icon={{
                                    url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                                    scaledSize: new window.google.maps.Size(40, 40),
                                }}
                                onClick={() => setSelectedPoint(point)}
                            />
                        ))}

                        {selectedPoint && (
                            <InfoWindow
                                position={{
                                    lat: selectedPoint.check.location.coordinates[0],
                                    lng: selectedPoint.check.location.coordinates[1],
                                }}
                                onCloseClick={() => setSelectedPoint(null)}
                            >
                                <a href={`#/student/${selectedPoint.student._id}`} target="_blank" className="text-center link-02">
                                    <div className="text-center">
                                        <img className="rounded-50 mb-3" src={selectedPoint.student.photo.uri} height="80" width="80" alt="student-photo"/>
                                    </div>
                                    <h6>Student Information</h6>
                                    <p>Student ID: {selectedPoint.student.id_number}</p>
                                    <p>Student Name: {selectedPoint.student.firstName} {selectedPoint.student.lastName}</p>
                                    <p>Check-in Time: {new Date(selectedPoint.check.date.iso).toLocaleString()}</p>
                                </a>
                            </InfoWindow>
                        )}
                    </GoogleMap>
                </div>
            )}
        </>
    );
};

export default TrackMap;
