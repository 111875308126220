import React, {useEffect, useState} from 'react';
import {GET_COLLECTION_DATA, SEARCH_COLLECTION_URL} from '../../api';
import 'datatables.net';
import 'datatables.net-responsive';
import $ from "jquery";
import cogoToast from "cogo-toast";
import {teacher} from "../../request";
import {capitalize} from "../../utils/capitalize";
import {useSelector} from "react-redux";
import TrackGMap from "../../components/map/TrackGMap";
import moment from "moment";

let coordinates = [];

const Route = () => {

    document.title = "Report";

    const auth = useSelector((state) => state.auth.user);

    const [coordinate, setCoordinate] = useState({});
    const [pinpoints, setPinpoints] = useState([]);

    const token = localStorage.getItem('jwtToken');
    const [openMap, setOpenMap] = useState(false);

    useEffect(() => {
        let td_route = $('#td-route').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Please Wait</p>'
            },
            "searching": true,
            "lengthMenu": [[10], [10]],
            ordering: false,
            searchDelay: 650,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_COLLECTION_DATA(auth.user.level), {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "routes",
                        fieldname: "name",
                        pageSize: data.start,
                        filter: {
                            academia: auth.user.sub
                        },
                        format: 'json',
                        populate: [
                            {
                                path: 'zone',
                                model: 'zone',
                                populate: [
                                    {
                                        path: 'patron',
                                        model: 'patron',
                                    },
                                    {
                                        path: 'bus',
                                        model: 'bus',
                                    },
                                    {
                                        path: 'driver',
                                        model: 'driver',
                                    }
                                ]
                            },
                            {
                                path: 'boards.student',
                                model: 'student',
                                populate: {
                                    path: 'photo',
                                    model: 'storage',
                                }
                            }
                        ],
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success : function(res) {
                        let array = [];
                        res.data.map((data) => {array.push({raw: data})});
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return `${data.zone.bus.model} ${data.zone.bus.number_plate}`;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `${data.zone.patron.first_name} ${data.zone.patron.last_name}`;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return {
                            complete : `<span class="badge badge-success tx-white">Complete</span>`,
                            pending : `<span class="badge badge-primary tx-white">Pending</span>`
                        }[data.status];
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return capitalize(data.type)
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return moment(data.createdAt).format("Do MMM, YYYY hh:ss");
                    },
                    "targets": 4
                },
                {
                    "render": function (data) {
                        return `<nav class="nav nav-icon-only">
                                    <a href="#" class="nav-link view_list"><i class="fa fa-users"></i> List</a><a href="#" class="nav-link track_map"><i class="fa fa-map"></i> ${data.status === "complete" ? "Records" : "Track"}</a>
                                </nav>`
                    },
                    "targets": 5
                }
            ]
        });

        td_route.on('click', '.track_map', function(e) {
            e.preventDefault();
            let extract_td = td_route.row($(this).closest('tr')).data();

            let coordinates = extract_td.raw.route_points.map((point) => ({
                lat: point.coordinates[0],
                lng: point.coordinates[1]
            }));

            $("#driver-name").text(extract_td.raw.zone.driver.first_name + ' ' +extract_td.raw.zone.driver.last_name);
            $("#driver-mobile").text(extract_td.raw.zone.driver.mobile_number);

            $("#patron-name").text(extract_td.raw.zone.patron.first_name + ' ' +extract_td.raw.zone.patron.last_name);
            $("#patron-mobile").text(extract_td.raw.zone.patron.mobile_number);

            setPinpoints(extract_td.raw.boards);
            setCoordinate(coordinates);
            setOpenMap(true);
            $("#modalMap").modal({ backdrop: 'static', focus: false, show: true });
        });

        td_route.on('click', '.view_list', function(e) {
            e.preventDefault();
            let extract_td = td_route.row($(this).closest('tr')).data();

            let students = [];

            extract_td.raw.boards.map((item) => {
                students.push([capitalize(item.student.firstName+' '+item.student.lastName), item.student.id_number, capitalize(extract_td.raw.type), moment(item.check.date).format("Do MMM, YYYY hh:ss"), item.student._id])
            });

            $('#student_table').DataTable({
                responsive: true,
                searching: true,
                lengthMenu: [[10], [10]],
                ordering: false,
                info: true,
                bFilter: false,
                pageLength: 10,
                data: students,
                columns: [
                    { title: 'Full Name' },
                    { title: 'ID Number' },
                    { title: 'Type' },
                    { title: 'Date' },
                    { title: 'Action' }
                ],
                columnDefs: [
                    {
                        "render": function (data) {
                            return `<nav class="nav nav-icon-only"><a href="#/student/${data}" class="nav-link view-map" target="_blank"><i class="fa fa-eye"></i> View Detail</a></nav>`
                        },
                        "targets": 4
                    }
                ]
            });

            $("#modalStudent").modal({backdrop: 'static', keyboard: false}, "show");
        })
    }, []);

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Report</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Report</h4>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Total Report</h6>
                                <div className="d-flex tx-18">
                                    <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10"
                                       onClick={() => $('#td-route').DataTable().ajax.reload()}><i
                                        className="icon ion-md-refresh"></i></a>
                                </div>
                            </div>
                            <div className="card-body table-responsive">
                                <table id="td-route" className="table" style={{width: "100%"}}>
                                    <thead className="thead-light">
                                    <tr>
                                        <th className="wd-20p">Bus</th>
                                        <th className="wd-20p">Patron</th>
                                        <th className="wd-15p">Status</th>
                                        <th className="wd-15p">Type</th>
                                        <th className="wd-15p">Date</th>
                                        <th className="wd-15p">Action</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalMap" tabIndex="-1" role="dialog" aria-labelledby="confirmation"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content tx-14">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel6">Track Map</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setOpenMap(false)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <ul className="list-inline d-flex mg-t-20 mg-b-20 mg-sm-t-10 mg-md-t-0 mg-b-0">
                                <li className="list-inline-item d-flex align-items-center">
                                    <span className="d-block wd-10 ht-10 bg-success rounded mg-r-5"></span>
                                    <span
                                        className="tx-sans tx-uppercase tx-10 tx-medium tx-color-03">Start Route</span>
                                </li>
                                <li className="list-inline-item d-flex align-items-center mg-l-5">
                                    <span className="d-block wd-10 ht-10 bg-danger rounded mg-r-5"></span>
                                    <span className="tx-sans tx-uppercase tx-10 tx-medium tx-color-03">End Route</span>
                                </li>
                                <li className="list-inline-item d-flex align-items-center mg-l-5">
                                    <span className="d-block wd-10 ht-10 bg-df-1 rounded mg-r-5"></span>
                                    <span className="tx-sans tx-uppercase tx-10 tx-medium tx-color-03">Student Pinpoint</span>
                                </li>
                            </ul>
                            {openMap ? <TrackGMap coordinates={coordinate} pinpoints={pinpoints}/> : null}
                            <div className="mt-3 row">
                                <div className="col-6 d-flex pd-sm-x-20">
                                    <div className="avatar"><span
                                        className="avatar-initial rounded-circle bg-gray-600">d</span></div>
                                    <div className="pd-l-10">
                                        <p className="tx-medium mg-b-0" id="driver-name">Driver Name</p>
                                        <small className="tx-12 tx-color-03 mg-b-0">Mobile #<span id="driver-mobile">00222</span>(Driver)</small>
                                    </div>
                                </div>
                                <div className="col-6 d-flex pd-sm-x-20">
                                    <div className="avatar"><span
                                        className="avatar-initial rounded-circle bg-brand-01">p</span></div>
                                    <div className="pd-l-10">
                                        <p className="tx-medium mg-b-0" id="patron-name">Patron Name</p>
                                        <small className="tx-12 tx-color-03 mg-b-0">Mobile #<span id="patron-mobile">00222</span>(Patron)</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalStudent" tabIndex="-1" role="dialog" aria-labelledby="confirmation"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content tx-14">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel6">Student List</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => $('#student_table').DataTable().destroy()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <table id="student_table" className="table" style={{width: "100%"}}></table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Route;
